import React, {Fragment} from 'react';
import '../../App.css';
import ListPublications from '../ListPublications.js';

function Publications() {

    return (
        <>
            <div>
                <ListPublications />
            </div>
        </>
    );
}

export default Publications;
