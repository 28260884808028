import React from 'react';
import '../../App.css';

function ContactMe() {
    return (
        <>
            <h1 className="contact-me">Contact Me</h1>
        </>
    )
}

export default ContactMe
