import React from 'react';
import '../../App.css';
import './../../components/pages/Bio.css';

function Bio() {
    return (
        <>
            <div className='bio-container'>
                <div className='bio-intro'>
                    <h1 className='h1-bio-intro'>“My experience and research is here to help you reach your life goals. The breadth and diversity of my experience make we well positioned to achieve this.
                    </h1>
                    <h2>Feel free to read through my experience below, or reachout to discuss. From day1, all my patients are managed with the upmost privacy, focus and dedication.”
                    </h2>
                    <h3>Leader and innovator within UK Cardiology - DR Shohreh Honarbakhsh.
                    </h3>
                </div>
                <div className='bio-main-pic'>
                </div>
            </div>
        </>
    )
}

export default Bio
