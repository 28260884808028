import React from 'react';
import '../../App.css';

function RhythmAI() {
    return (
        <>
            <h1 className="rhythmai">RhythmAI</h1>
        </>
    )
}

export default RhythmAI
